import { useAppState } from '@/hooks/useAppState';
import { ComponentProps } from '@/types/component';
import { tw } from '@/utils/tw';
import { GenericSlotRender } from 'base/components/GenericSlot';
import { gridSystemConfig } from 'base/configs/gridSystem';
import { labradorImageLoader } from 'lib/image';
import { getBackendImageServer, getImageServer } from 'lib/utils';
import getUrl from 'lib/utils/getUrl';
import { merge } from 'lib/utils/merge';
import NextImage from 'next/image';
import { cloneElement, useState } from 'react';
import { isEmpty, isString, isUndefined } from 'typesafe-utils';

const placeholder = {
  mobile: `data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1NjgiIGhlaWdodD0iMjg5IiBmaWxsPSJub25lIiB4bWxuczp2PSJodHRwczovL3ZlY3RhLmlvL25hbm8iPjxwYXRoIGZpbGw9IiNlNmU2ZTYiIGQ9Ik0wIDBoNTY4djI4OUgweiIvPjxnIGZpbGw9IiM4ZDhkOGQiPjxjaXJjbGUgY3g9IjI1OSIgY3k9IjE0NSIgcj0iOSIvPjxjaXJjbGUgY3g9IjI4NC4xOTUiIGN5PSIxNDUiIHI9IjkiLz48Y2lyY2xlIGN4PSIzMDkuNDAyIiBjeT0iMTQ1IiByPSI5Ii8+PC9nPjwvc3ZnPg==`,
  desktop: `data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MDAiIGhlaWdodD0iMjA3IiBmaWxsPSJub25lIiB4bWxuczp2PSJodHRwczovL3ZlY3RhLmlvL25hbm8iPjxwYXRoIGZpbGw9IiNlNmU2ZTYiIGQ9Ik0wIDBoNDAwdjIwN0gweiIvPjxnIGZpbGw9IiM4ZDhkOGQiPjxjaXJjbGUgY3g9IjE3NSIgY3k9IjEwNCIgcj0iOSIvPjxjaXJjbGUgY3g9IjIwMC4xOTUiIGN5PSIxMDQiIHI9IjkiLz48Y2lyY2xlIGN4PSIyMjUuNDAyIiBjeT0iMTA0IiByPSI5Ii8+PC9nPjwvc3ZnPg==`,
};

const getImageUrl = (src: BaseProps['src'], fallback: URL): URL => {
  if (isUndefined(src) || !isString(src) || isEmpty(src) || src === '/') {
    return fallback;
  }

  return getUrl(src, getImageServer()) ?? fallback;
};

interface BaseProps extends ComponentProps<typeof NextImage> {
  maxWidth?: number;
}

const Render: GenericSlotRender = ({ element, props: { alt, src, ...props } }) => {
  const [{ device }] = useAppState();
  const [isError, setError] = useState(false);

  const blurDataURL = getUrl(
    props?.blurDataURL || (device === 'desktop' ? placeholder.desktop : placeholder.mobile),
  ) as URL;

  const { searchParams } = isString(src) ? getImageUrl(src, blurDataURL) : ({} as never);

  const dimensions = {
    width: Number(props?.width || searchParams?.get('width')) || undefined,
    height: Number(props?.width || searchParams?.get('height')) || undefined,
    scaled: {
      width: 0,
      height: 0,
    },
  };

  const scale = dimensions.width && props?.maxWidth ? Math.min(props?.maxWidth / dimensions.width, 1) : 1;

  if (scale < 1) {
    dimensions.scaled.width = Math.round(dimensions.width! * scale);
    dimensions.scaled.height = Math.round(dimensions.height! * scale);

    if (searchParams?.has('width')) {
      searchParams.set('width', String(dimensions.scaled.width));
    }

    if (searchParams?.has('height')) {
      searchParams.set('height', String(dimensions.scaled.height));
    }
  }

  const fill = props?.fill || !dimensions.width || !dimensions.height;

  const maxContentWidth = props?.maxWidth ? `${props?.maxWidth}px` : gridSystemConfig.screens.lg.maxContentWidth;
  const sizes = `(max-width: ${maxContentWidth}) 100vw, ${maxContentWidth}`;

  const dimensionsProps = fill
    ? null
    : {
        width: dimensions.scaled.width || dimensions.width,
        height: dimensions.scaled.height || dimensions.height,
      };

  if (isString(src) && src.startsWith(getBackendImageServer())) props.referrerPolicy = 'no-referrer';

  const resolvedProps = merge(
    {
      alt,
      blurDataURL: blurDataURL.href,
      fill,
      loader: labradorImageLoader,
      onError: () => setError(true),
      placeholder: 'blur',
      sizes,
      src: isError ? blurDataURL.href : src,
      ...dimensionsProps,
    },
    props,
  );

  return (
    <picture
      data-fill={fill ? '' : undefined}
      className={tw.merge(resolvedProps.className, fill && 'relative')}
      style={resolvedProps.style}
    >
      {cloneElement(element, resolvedProps)}
    </picture>
  );
};

export default {
  render: Render,
};
