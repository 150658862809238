import { ImageTheme } from '@/components/Image/theme';
import { GenericSlotFactory } from 'base/components/GenericSlot';
import NextImage, { ImageProps as NextImageProps } from 'next/image';
import React from 'react';
import base from './slots/base';

type NextImage = React.ForwardRefExoticComponent<
  Omit<NextImageProps, 'src' | 'alt'> &
    Partial<Pick<NextImageProps, 'src' | 'alt'>> & {
      maxWidth?: number;
    }
>;

export const ImageContext = React.createContext({});

const $ = GenericSlotFactory({ theme: ImageTheme, context: ImageContext });

const Base = $({ as: NextImage as NextImage, slot: 'base', render: base.render });
const Caption = $({ as: 'figcaption', slot: 'caption' });
const Group = $({ as: 'figure', slot: 'group' });
const Byline = $({ as: 'span', slot: 'byline' });

export const Image = Object.assign(Base, {
  Caption,
  Byline,
  Group,
});
