import { OptionalLink, OptionalLinkProps } from '@/components/OptionalLink';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { Image, ImageContext } from './Image';

export interface StandaloneImageProps
  extends StandaloneComponentProps<typeof Image>,
    Partial<Pick<React.ComponentProps<typeof Image>, 'alt' | 'src'>> {
  byline?: React.ReactNode;
  caption?: React.ReactNode;
  link?: OptionalLinkProps;
}

export const StandaloneImage: StandaloneComponent<StandaloneImageProps> = ({
  alt,
  src,
  byline,
  caption,
  link,
  ...props
}) => {
  return (
    <ImageContext.Provider value={{ ...props?.options }}>
      <Image.Group>
        <OptionalLink content={<Image {...{ src: src!, alt: alt! }} {...props} />} {...link} />

        {(caption || byline) && (
          <Image.Caption>
            {caption}
            {byline && (
              <>
                {' '}
                <Image.Byline>{byline}</Image.Byline>
              </>
            )}
          </Image.Caption>
        )}
      </Image.Group>
    </ImageContext.Provider>
  );
};
