import { tw } from '@/utils/tw';
import BaseImage from 'base/components/standalone/Image/theme';

const Image = tw.theme({
  extend: BaseImage,
  slots: {
    caption: 'flex flex-col gap-y-1 text-black text-headline-xs',
    byline: 'italic text-black',
  },
});

export default Image;
